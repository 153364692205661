import { Button, Form, Input, Select } from 'antd';
import React, { useState } from 'react';
import classes from './index.module.scss';
import notify, { notifyError } from '../../utils/notify';
import useCopyGroupResources from './query';

const CopyGroupResources = Form.create()(({ form }) => {
  const { getFieldDecorator } = form;
  const [isLoading, setIsloading] = useState(false);
  const urlRegexp =
    // eslint-disable-next-line no-useless-escape
    /^https?:\/\/[^\/]+\/hub\/[^\/]+\/groups\/([^\/?]+)(\/[^?]*)?(\?.*)?$/;
  const copyGroupResources = useCopyGroupResources();

  const hubIdValidator = (rule, value, callback) => {
    if (value === undefined || value === '') {
      callback('Hub ID is required');
    } else if (isNaN(value)) {
      callback('Hub ID must be a number');
    } else {
      callback();
    }
  };

  const validateUrl = (rule, value, callback) => {
    if (!value) {
      callback('Please enter at least one URL');
      return;
    }
    const fromGroupUrl = form.getFieldValue('fromGroupUrl');
    const invalidUrls = value.filter(url => !urlRegexp.test(url.trim()));

    if (value.includes(fromGroupUrl)) invalidUrls.push(fromGroupUrl);

    if (invalidUrls.length > 0) {
      callback(`Invalid URL(s): ${invalidUrls.join(', ')}`);
    } else {
      callback();
    }
  };

  const handleFormCancel = () => {
    form.resetFields();
  };

  const isSubmitDisabled = () =>
    !form.getFieldValue('fromGroupUrl') ||
    !form.getFieldValue('toGroupUrls')?.length;

  const handleSubmit = async e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        try {
          setIsloading(true);
          copyGroupResources.mutate(values, {
            onSuccess: () => {
              notify('Sucessfully copied resources', 'success');
              setIsloading(false);
            },
            onError: error => {
              notifyError(error, false, 'Something went wrong');
              setIsloading(false);
            },
          });
        } catch (errr) {
          notifyError(errr, false, 'Unexpected error');
          setIsloading(false);
        }
      } else {
        notifyError(err, false, 'Invalid URLs');
      }
    });
  };

  return (
    <div className={classes.container}>
      <Form layout="vertical">
        <Form.Item label="Hub Id">
          {getFieldDecorator('hubId', {
            rules: [
              {
                required: true,
                message: 'Please enter the hubId',
              },
              {
                validator: hubIdValidator,
              },
            ],
          })(<Input placeholder="HubId" />)}
        </Form.Item>
        <Form.Item label="Copy resources from this group URL">
          {getFieldDecorator('fromGroupUrl', {
            rules: [
              {
                required: true,
                message: 'Please enter group about page URL',
              },
              {
                pattern: urlRegexp,
                message: 'Invalid url',
              },
            ],
          })(
            <Input placeholder="https://pg-test.com/hub/test-hub/groups/group-name/about" />
          )}
        </Form.Item>
        <Form.Item label="Copy resources to this group URL's">
          {getFieldDecorator('toGroupUrls', {
            rules: [
              { required: true, message: 'Please enter group URL' },
              { validator: validateUrl },
            ],
          })(
            <Select
              mode="tags"
              placeholder="Enter multiple URLs"
              tokenSeparators={[',']}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="default"
            onClick={handleFormCancel}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={isSubmitDisabled() || isLoading}
            loading={isLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
});

export default CopyGroupResources;
