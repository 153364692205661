import React from 'react';
import { map } from 'lodash';

import { Col, Input, Select, InputNumber, Row } from 'antd';

const { TextArea } = Input;
const { Option } = Select;

const QueryTypes = [
  {
    value: 'cp',
    name: 'Career Path',
  },
  {
    value: 'user',
    name: 'User',
  },
];

const StandardUserTypes = [
  {
    value: 'student',
    name: 'Student',
  },
  {
    value: 'alumni',
    name: 'Alumni',
  },
  {
    value: 'Faculty & Staff',
    name: 'faculty',
  },
  {
    value: 'Friends Of',
    name: 'friend',
  },
];

const LLMRecommendations = ({ currentApi, body = {}, setBody }) => {
  const allBodyTypes = [
    {
      id: 'body',
      api: [
        'users-weighted-recommendations',
        'users-weighted-search',
        'users-cp-weighted-search',
        'jobs-recommendations-es',
        'jobs-search-es',
        'get-group-recommendations',
        'pathwayU-program-recommendations',
      ],
      placeholder: 'Body Param in JSON String',
      Component: (
        <TextArea
          rows={10}
          placeholder="Body"
          value={body.body}
          onChange={e => setBody({ body: e.target.value })}
          style={{ width: '100%' }}
          required
        />
      ),
    },
    {
      id: 'hub_id',
      api: ['users-recommendations'],
      placeholder: 'Hub Id',
      Component: (
        <InputNumber
          placeholder="Hub Id"
          value={body.hub_id}
          onChange={val => setBody({ hub_id: val })}
          allowClear
          style={{ width: '100%' }}
        />
      ),
    },
    {
      id: 'query_type',
      api: ['users-recommendations'],
      Component: (
        <Select
          placeholder="Query Type"
          value={body.query_type}
          onChange={val => setBody({ query_type: val })}
          allowClear
          style={{ width: '100%' }}
        >
          {map(QueryTypes, source => (
            <Option key={source.value} value={source.value}>
              {source.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      id: 'query_id',
      api: ['users-recommendations'],
      Component: (
        <Input
          placeholder="Query Id"
          value={body.query_id}
          onChange={e => setBody({ query_id: e.target.value })}
          allowClear
          style={{ width: '100%' }}
        />
      ),
    },
    {
      id: 'standard_user_type',
      api: ['users-recommendations'],
      Component: (
        <Select
          placeholder="Standard User Type"
          value={body.standard_user_type}
          onChange={val => setBody({ standard_user_type: val })}
          allowClear
          style={{ width: '100%' }}
        >
          {map(StandardUserTypes, source => (
            <Option key={source.value} value={source.value}>
              {source.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      id: 'number_recommendations',
      api: ['users-recommendations'],
      Component: (
        <InputNumber
          placeholder="Limit"
          value={body.number_recommendations}
          onChange={val => setBody({ number_recommendations: val })}
          allowClear
          style={{ width: '100%' }}
        />
      ),
    },
    {
      id: 'excluded_ids',
      api: ['users-recommendations'],
      Component: (
        <Input
          placeholder="Excluded User IDs (Comma separated)"
          value={body.excluded_ids}
          onChange={e => setBody({ excluded_ids: e.target.value })}
          allowClear
          style={{ width: '100%' }}
        />
      ),
    },
  ];
  const apiBodyTypes = allBodyTypes.filter(({ api }) =>
    api.includes(currentApi)
  );
  if (!apiBodyTypes.length) {
    return null;
  }

  return (
    <>
      <Row type="flex" justify="space-around" gutter={8}>
        {apiBodyTypes.slice(0, 4).map(({ id, Component }) => (
          <Col key={id} span={Math.max(6, 12 / apiBodyTypes.length)}>
            {Component}
          </Col>
        ))}
      </Row>
      {apiBodyTypes.length > 4 ? (
        <Row
          type="flex"
          justify="space-around"
          gutter={8}
          style={{ marginTop: '16px' }}
        >
          {apiBodyTypes.slice(4).map(({ id, Component }) => (
            <Col key={id} span={6}>
              {Component}
            </Col>
          ))}
        </Row>
      ) : null}
    </>
  );
};

export default LLMRecommendations;
